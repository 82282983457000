const CACHED_PROMISES: Record<string, Promise<any>> = {}

export default function <T> (url: string, varName: string, removeGlobal = true) {
  if (typeof CACHED_PROMISES[url] === 'undefined') {
    CACHED_PROMISES[url] = new Promise<T>((resolve, reject) => {
      let script = document.createElement('script')
      script.onload = () => {
        if (typeof (window as unknown as Record<string, T>)[varName] !== 'undefined') {
          resolve((window as unknown as Record<string, T>)[varName])
          if (removeGlobal) {
            delete (window as unknown as Record<string, T>)[varName]
          }
        } else {
          delete CACHED_PROMISES[url]
          reject(new Error(`Unable to find window.${varName}!`))
        }
      }
      script.onerror = (error) => {
        delete CACHED_PROMISES[url]
        reject(error)
      }
      script.async = true
      script.src = url
      document.head.appendChild(script)
    })
  }
  return CACHED_PROMISES[url] as Promise<T>
}
